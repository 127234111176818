import type { ComponentProps } from "react"
import cn from "@/v1-ui/utils/utils.cn"
import type { Intent } from "@/v1-ui/types"
import type { IconNames } from "./icon.types"

export type IconProps = ComponentProps<"span"> & {
  intent?: Intent,
  size?: number,
  src?: IconNames,
  loading?: boolean,
  alt?: string
}

function Icon(props: IconProps) {
  const {
    intent,
    className,
    style,
    size = 15,
    src,
    loading,
    role = "img",
    ...spanProps
  } = props

  const finalStyle = loading
    ? {
        minHeight: size,
        minWidth: size,
        maxWidth: size,
        maxHeight: size,
        ...style
      }
    : {
        fontSize: size,
        ...style
      }

  return (
    <span
      className={cn("icn fi", className, intent, {
        "is-loading": loading,
        [`fi-rr-${src}`]: !loading
      })}
      role={loading
        ? "progressbar"
        : role
      }
      style={finalStyle}
      {...spanProps}
    />
  )
}

export default Icon
